/* swiper style override */
.swiper-button-next
{
    color: rgb(var(--color-primary-lightest)) !important;
}

.swiper-button-prev
{
    color: rgb(var(--color-primary-lightest)) !important;
}

/* NOTIFICATION */
.rnc__notification-container--top-right div
{
    max-width: unset !important;
}
.rnc__notification-container--top-left div
{
    text-align: right !important;
    max-width: unset !important;
}
.rnc__notification-container--top-left .rnc__notification-timer
{
    -webkit-transform: scaleX(1) !important;
    transform: scaleX(1) !important;
}

/* google map override */
.google-map-custom-wrapper > div
{
    border-radius: 1rem;
}

/* MOYASER */
.mysr-form-label, .mysr-form-footer
{
    display: none !important;
}
.mysr-form-button
{
    display: none !important;
}
.mysr-form-inputGroup
{
    margin:0px !important;
}
#form-payment-credit-wallet-rtl #mysr-cc-name
{
    text-align: right;
}
.mysr-form-requiredAlert
{
    display: none !important;
}
#mysr-form-form-el#mysr-form-form-el#mysr-form-form-el.mysr-form-moyasarForm.mysr-form-fixedWidth
{
    width: 100% !important;
    max-width: 100% !important;
}
.mysr-form-input
{
    height: 40px !important;
    border-width: 2px !important;
    font-size: clamp(0.375rem, 5vw, 0.75rem) !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}
.mysr-form-input[theme="light"]
{
    color: #0d0d0d !important;
    background-color: #f5f7f8 !important; 
    border-color: transparent !important;
    box-shadow: 0 0 0 1px #ededed, 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
.mysr-form-input[theme="dark"]
{
    color: #ffffff !important;
    background-color: #3c434d !important; 
    border-color: transparent !important;
    box-shadow: 0 0 0 1px #ffffff1f, 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
}